<!--
 * @Author: frank
 * @Description: 
 * @Date: 2021-01-18 09:19:52
 * @LastEditTime: 2021-05-13 09:34:03
 * @FilePath: /shop_frontend/src/views/orderReturn/index.vue
-->
<template>
  <div class="page">
    <div class="formBox">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="订单号">
              <a-input
                placeholder="订单编号"
                style="width: 220px"
                v-model="form.sales_order_number"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="退款编号">
              <a-input
                placeholder="退款编号"
                style="width: 220px"
                v-model="form.number"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单人名称">
              <a-input
                placeholder="下单人名称"
                style="width: 220px"
                allowClear
                v-model="form.client_name"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="下单人手机">
              <a-input
                placeholder="下单人手机"
                style="width: 220px"
                allowClear
                v-model="form.client_phone"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="退款状态">
              <a-select
                style="width: 220px"
                placeholder="请选择退款状态"
                allowClear
                v-model="form.status"
              >
                <a-select-option
                  :value="item.value"
                  :key="item.value"
                  v-for="item in statusList"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              <a-range-picker
                allowClear
                v-model="form.date"
                valueFormat="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-item>
              <a-button
                type="primary"
                style="margin-right: 10px"
                @click="handleSearch"
              >
                筛选
              </a-button>
              <!-- <a-button type=""> 导出 </a-button> -->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="全部"> </a-tab-pane>
        <a-tab-pane key="0" tab="商家待处理" force-render> </a-tab-pane>
        <a-tab-pane key="2" tab="买家待处理"> </a-tab-pane>
        <a-tab-pane key="5" tab="已完成"> </a-tab-pane>
      </a-tabs>
    </div>
    <div class="page__table">
      <table class="custom__table">
        <colgroup>
          <col width="20%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="20%" />
          <!-- <col width="10%" /> -->
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <tr style="background: #f5f5f5;font-weight: 700;">
          <td>商品名称</td>
          <td>商品数量</td>
          <td>单价</td>
          <!-- <td>退款类型</td> -->
          <td>下单人信息</td>
          <td>退款原因</td>
          <td>退款状态</td>
          <td>操作</td>
        </tr>
      </table>

      <table
        class="custom__table"
        v-for="item in dataSource"
        :key="'order' + item.id"
        style="margin: 20px 0"
      >
        <colgroup>
          <col width="20%" />
          <col width="10%" />
          <col width="10%" />
          <col width="10%" />
          <col width="20%" />
          <!-- <col width="10%" /> -->
          <col width="10%" />
          <col width="10%" />
        </colgroup>
        <tr style="background: #f5f5f5">
          <td colspan="9">
            <div class="flex custom__table_order">
              <span>退款编号：{{ item.number }}</span>
              <span>订单号：{{ item.sales_order_item.number }}</span>
              <span>申请时间：{{ item.create_time }}</span>
              <span>退款金额：{{ item.total_amount || 0 }}</span>
              <span>运费退款金额：{{ item.freight_amount || 0 }}</span>
            </div>
          </td>
        </tr>
        <tr v-for="el in item.sales_order_item.sales_goods_items" :key="el.id">
          <td>
            <div class="name__box">
              <img
                :src="el.spec_picture_url"
                alt=""
                style="
                  display: inline-block;
                  width: 80px;
                  height: 80px;
                  margin-right: 10px;
                "
              />
              <div>
                <div>{{ el.goods_name }}</div>
                <div>{{ el.spec_name }}</div>
              </div>
            </div>
          </td>
          <td>{{ el.quantity }}</td>
          <td>{{ el.member_price }}</td>
          <!-- <td>{{ item.refund_reason }}</td> -->
          <td>
            <p>{{ item.sales_order_item.client_name }}</p>
            <p>{{ item.sales_order_item.client_phone }}</p>
            <p>{{ item.sales_order_item.client_address }}</p>
          </td>
          <td>{{ showReson(item.refund_reason) }}</td>
          <td>{{ item.status | returnStatusShow }}</td>
          <td>
            <div>
              <a-button
                :type="item.status != 5 ? 'primary' : ''"
                size="small"
                @click="openDetail(item)"
              >
                {{ item.status == 5 ? "查看详情" : "处理退款" }}
              </a-button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div style="text-align: right; margin-top: 20px">
      <a-pagination v-bind="pagination" />
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderReturn",
  computed: {
    params() {
      return {
        ...this.form,
        start_date: this.form.date[0] || "",
        end_date: this.form.date[1] || "",
      };
    },
  },
  data() {
    return {
      statusList: [
        {
          value: 0,
          name: "退款申请等待商家确认",
        },
        {
          value: 1,
          name: "商家拒绝退款申请",
        },
        {
          value: 2,
          name: "商家同意退款, 等待买家退货",
        },
        {
          value: 3,
          name: "商家未收货, 拒绝退款",
        },
        {
          value: 4,
          name: "退款撤销",
        },
        {
          value: 5,
          name: "退款成功",
        },
        {
          value: 6,
          name: "系统处理中",
        },
      ],
      dataSource: [],
      pagination: {
        total: 0,
        showTotal: (total, range) => {
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
        defaultPageSize: 15,
      },
      page: 1,
      form: {
        date: [],
        status: 0,
      },
    };
  },
  created() {
    this.initTable();
  },
  methods: {
    callback(key) {
      if (key === "1") {
        // 全部
        this.form.status = null;
      } else {
        this.form.status = parseInt(key);
      }
      this.initTable();
    },
    showReson(str) {
      return {
        0: "协商一致退款",
        1: "拍错/多拍/不想要",
        2: "其他",
        3: "订单取消",
      }[str];
    },
    openDetail(obj) {
      this.$router.push({
        name: "returnDetail",
        query: {
          id: obj.id,
        },
      });
    },
    handleSearch() {
      this.initTable();
    },
    initTable() {
      this.$axios
        .get("/refund_orders/", { params: this.params })
        .then((res) => {
          this.dataSource = res.results || [];
          this.pagination.total = res.count || 0;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.name__box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.btns {
  display: flex;
  > span {
    margin-left: 20px;
    color: #549ff8;
    cursor: pointer;
  }
}
.search__price {
  display: flex;
  align-items: center;
  > span {
    margin: 0 10px;
  }
}
.custom__table {
  width: 100%;
  td {
    border: 1px solid #f2f2f2;
    text-align: center;
    height: 40px;
    word-break: break-all;
    padding: 4px;
  }
  .custom__table_order {
    & > span {
      flex: 1;
    }
  }
}
</style>
